import React, { useState } from "react";
import { connect as connectFela } from "react-fela";
import BlockContent from "@sanity/block-content-to-react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Button from "components/Button";
import CircledTitle from "components/CircledTitle";
import Container from "components/container";
import H3 from "components/typography/H3";
import P from "components/typography/P";
import UL from "components/typography/UL";
import calc from "utilities/calc";
import fluidValues from "utilities/fluidValues";
import { extractFilename } from '../commands/playbookUrl'
import { isMobile, isTouch } from "utilities/tools";
import { serializers as linkSerializers } from "components/BaseLink.js";

const serializers = ({ styles, rules }) => {
  return {
    list: (props) => <UL>{props.children}</UL>,
    listItem: (props) => (
      <P tag={"li"} version="list">
        {props.children}
      </P>
    ),
    types: {
      block: (props) => {
        const style = props.node.style;
        if (style === "title") {
          return (
            <H3 content extend={{ styles: rules.h3 }}>
              {props.children}
            </H3>
          );
        }

        if (style === "normal") {
          return (
            <P version="medium" extend={{ styles: rules.normal }}>
              {props.children}
            </P>
          );
        }

        if (style === "xlarge") {
          return <P version="xlarge">{props.children}</P>;
        }

        return BlockContent.defaultSerializers.types.block(props);
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      highlight: (props) => (
        <span className={styles.highlight}>{props.children}</span>
      ),
      colored: (props) => (
        <span className={styles.colored}>{props.children}</span>
      ),
      indented: (props) => (
        <div className={styles.indented}>{props.children}</div>
      ),
    },
  };
};

const BrochureDownload = ({
  rules,
  styles,
  title,
  color,
  brochure,
  image,
  description,
  points,
  buttonlabel,
}) => {
  const filename = extractFilename(brochure);
  return (
    <Container fullscreen extend={{ styles: rules.brochureDownloadContainer }}>
      {title && (
        <Container
          extraLeft
          flex
          withContentArea5
          extend={{ styles: rules.circledTitle }}
        >
          <CircledTitle color={color}>{title}</CircledTitle>
        </Container>
      )}

      {image && image.asset && (
        <Img
          className={styles.image}
          image={image.asset.gatsbyImageData}
          alt={""}
        />
      )}

      {description && (
        <Container extend={{ styles: rules.descriptionContainer }}>
          <div className={styles.description}>
            <BlockContent
              blocks={description}
              serializers={serializers({ rules, styles })}
            />

            {points && (
              <div className={styles.points}>
                <UL>
                  {points.map((point) => (
                    <P tag={"li"} key={point} version="list">
                      {point}
                    </P>
                  ))}
                </UL>
              </div>
            )}
          </div>

          {brochure && (
            <div className={styles.bottomContainer}>
              <div className={styles.playbutton}>
                {brochure.asset && (
                  <Button
                    extend={{ styles: rules.button }}
                    download={filename}
                    to={brochure}
                  >
                    {buttonlabel}
                  </Button>
                )}
              </div>
            </div>
          )}
        </Container>
      )}
    </Container>
  );
};

/*
 * Only style the inside, the asymertric alignment should be dealt with in the parent components
 */
const styles = (props) => ({
  circledTitle: {
    phoneOnly: {
      marginBottom: 30,
    },
  },
  brochureDownloadContainer: {
    ...fluidValues({ paddingTop: [30, 110] }),
  },
  playbutton: {
    marginBottom: 40,
    ...fluidValues({
      paddingLeft: [24, 110],
    }),

    phoneOnly: {
      marginTop: 24,
    },
  },
  descriptionContainer: {
    ...fluidValues({ marginBottom: [48, 86] }),
  },
  h3: {
    ...fluidValues({ marginBottom: [48, 32] }),
  },
  button: {
    width: "100%",
    maxWidth: 404,
    marginBottom: "auto",
    boxSizing: "border-box",

    tabletLandscapeAndUp: {
      marginRight: calc(80, 143, 1200, 1440),
    },
  },
  points: {
    display: "flex",
    flex: "1 0 42%",
    justifyContent: "flex-start",
    ...fluidValues({
      marginTop: [48, 30],
    }),
    phoneOnly: {
      order: 2,
    },
  },
  description: {
    position: "relative",
    borderLeft: "1px solid colors.colorConsole500",
    maxWidth: 953,

    ...fluidValues({
      paddingLeft: [24, 110],
      paddingTop: [74, 220],
      marginTop: [-31, -130],
      marginBottom: [48, 80],
    }),

    extend: {
      condition: props.extraLeft,
      style: {
        "@media(min-width: 768px)": {
          marginLeft: calc(0, 220, 768, 1440),
        },
      },
    },
  },
  bottomContainer: {
    extend: [
      {
        condition: props.extraLeft,
        style: {
          "@media(min-width: 768px)": {
            marginLeft: calc(0, 220, 768, 1440),
          },
        },
      },
    ],
  },
  normal: {
    fontWeight: 400,
  },
});

BrochureDownload.defaultProps = {
  buttonlabel: "",
  color: "",
  description: null,
  image: {},
  brochure: {},
  points: [],
  rules: {},
  styles: {},
  title: "",
};

BrochureDownload.propTypes = {
  buttonlabel: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.array,
  image: PropTypes.object,
  brochure: PropTypes.object,
  points: PropTypes.array,
  title: PropTypes.string,
  rules: PropTypes.object,
  styles: PropTypes.object,
};

export default connectFela(styles)(BrochureDownload);
