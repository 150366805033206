/**
 * @fileOverview Circled H1 component
 * @name CircledTitle.js
 */
import React from 'react'
import { connect as connectFela } from "react-fela"

import colors from "theme/colors"
import fluidDimensions from "utilities/fluidDimensions"
import fluidValues from "utilities/fluidValues"
import UnderlinedTitle from "components/UnderlinedTitle"

/**
 * Underlined H1 component with a circle around
 *
 * @param {object} styles Fela styles
 * @param {object} rules Fela rules
 * @param {array} children Children components, usually just text
 *
 * @return {jsx}
 */
const circledTitle = ({styles, rules, children, color, light}) => (
  <UnderlinedTitle extend={{styles: rules.styles}} light={light}>
    <div className={styles.circle} />
    <div className={styles.titleContainer}>{children}</div>
  </UnderlinedTitle>
)

/* Styles */
const styles = () => ({
  marginBottom: '0 !important',
  position: 'relative',
})

const titleContainer = () => ({
  position: 'relative',
  zIndex: 2,
})

const circle = (props) => ({
  ...fluidDimensions({width: [233, 514], height: [233, 514]}),
  extend: {
    ...fluidValues({
      top: [30, 110],
      left: [-47, -110],
    })
  },
  backgroundColor: (props.color ? colors[`color${props.color}200`] : colors.colorCanvas200),
  borderRadius: '50%',
  bottom: 0,
  margin: 'auto',
  position: 'absolute',
  right: 0,
  zIndex: 1,
})


/* Component */
const CircledTitle = connectFela({circle, titleContainer, styles})(circledTitle)
export default CircledTitle
