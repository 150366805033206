import React, { useState } from "react"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import PropTypes from "prop-types"

import { serializers as linkSerializers } from "components/BaseLink.js"
import Button from "components/Button"
import CircledTitle from "components/CircledTitle"
import Container from "components/container"
import H3 from "components/typography/H3"
import P from "components/typography/P"
import Ul from "components/typography/UL"
import calc from "utilities/calc"
import fluidValues from "utilities/fluidValues"
import SignupForm from "components/SignupForm"
import Modal from "components/Modal"
import { isMobile, isTouch } from "utilities/tools"
import * as playbookUrl from "commands/playbookUrl"

const serializers = ({styles, rules}) => {
  return {
    list: props => (
      <Ul>{props.children}</Ul>
    ),
    listItem: props => (
      <P tag={'li'} version="list">{props.children}</P>
    ),
    types: {
      block: (props) => {
        const style = props.node.style
        if (style === 'title') {
          return <H3 content extend={{styles: rules.h3}}>{props.children}</H3>
        }

        if (style === 'normal') {
          return <P version='medium' extend={{styles: rules.normal}}>{props.children}</P>
        }

        if (style === 'xlarge') {
          return <P version="xlarge">{props.children}</P>
        }

        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      highlight: props => (
        <span className={styles.highlight}>
          {props.children}
        </span>
      ),
      colored: props => (
        <span className={styles.colored}>
          {props.children}
        </span>
      ),
      indented: props => (
        <div className={styles.indented}>
          {props.children}
        </div>
      )
    }
  }
}

const Playbook = ({
  rules,
  styles,
  title,
  color,
  playbook,
  image,
  description,
  points,
  buttonlabel,
  setEmergentModal,
}) => {
  const [interestModal, setInterestModal] = useState(false)

  return (<Container fullscreen extend={{styles: rules.playbookContainer}}>
    {title && <Container extraLeft flex withContentArea5 extend={{styles: rules.circledTitle}}>
      <CircledTitle color={color}>
        {title}
      </CircledTitle>
    </Container>}

    {image && image.asset && <Img className={styles.image} image={image.asset.gatsbyImageData} alt={""}/>}

    {description && <Container extend={{styles: rules.descriptionContainer}}>
      <div className={styles.description}>
        <BlockContent blocks={description} serializers={serializers({rules, styles})} />

        {points &&
         <div className={styles.points}>
           <Ul>
             {points.map((point) =>
               <P tag={'li'} key={point} version="list">{point}</P>
             )}
           </Ul>
         </div>}
      </div>

      {playbook && playbook.asset && <div className={styles.bottomContainer}>
        <div className={styles.playbutton}>
          {playbook.file && <Button
                                      extend={{styles: rules.button}}
                                      download={playbookUrl.extractFilename(playbook.file)}
                                      to={playbookUrl.buildUrl(playbook.file)}>{buttonlabel}</Button>}
        </div>
      </div>}

      {!playbook && <div className={styles.bottomContainer}>
        <div className={styles.playbutton}>
          <Button onClick={(e) => { e.preventDefault(); setInterestModal(true) }} extend={{styles: rules.button}} to={''}>TELL ME WHEN IT'S READY</Button>
        </div>

        <Modal opened={interestModal} close={() => setInterestModal(false)}>
          <SignupForm
            title={"Let me know when it's ready"}
            color={"#000000"}
            thankYouMessage={"Thanks for signing up for our From Project to Product playbook! We'll email you as soon as it's ready."}
            cmListID={"b75b87542d5ba612e65b7a5a8d162f7a"} />
        </Modal>
      </div>}
    </Container>}
  </Container>)
}

/*
 * Only style the inside, the asymertric alignment should be dealt with in the parent components
 */
const styles = (props) => ({
  circledTitle: {
    phoneOnly: {
      marginBottom: 30,
    }
  },
  playbookContainer: {
    zIndex: 1,
    ...fluidValues({paddingTop:  [30, 110]})
  },
  playbutton: {
    marginBottom: 40,
    ...fluidValues({
      paddingLeft: [24, 110],
    }),

    phoneOnly: {
      marginTop: 24,
    }
  },
  descriptionContainer: {
    ...fluidValues({marginBottom: [48, 86]}),
  },
  h3:{
    ...fluidValues({marginBottom: [48, 32]}),
  },
  button: {
    width: '100%',
    maxWidth: 404,
    marginBottom: 'auto',
    boxSizing: 'border-box',

    tabletLandscapeAndUp: {
      marginRight: calc(80, 143, 1200, 1440),
    },
  },
  points: {
    display: 'flex',
    flex: '1 0 42%',
    justifyContent: 'flex-start',
    ...fluidValues({
      marginTop: [48, 30],
    }),
    phoneOnly: {
      order: 2,
    }
  },
  description: {
    position: 'relative',
    borderLeft: '1px solid colors.colorConsole500',
    maxWidth: 953,

    ...fluidValues({
      paddingLeft: [24, 110],
      paddingTop: [74, 220],
      marginTop: [-15, -100],
      marginBottom: [28, 60],
    }),

    extend: {
      condition: props.extraLeft,
      style: {
        '@media(min-width: 768px)': {
          marginLeft: calc(0, 220, 768, 1440),
        }
      }
    }
  },
  bottomContainer: {
    extend: [{
      condition: props.extraLeft,
      style: {
        '@media(min-width: 768px)': {
          marginLeft: calc(0, 220, 768, 1440),
        }
      }
    }]
  },
  normal: {
    fontWeight: 400,
  }
})

Playbook.defaultProps = {
  buttonlabel: '',
  color: '',
  description: null,
  image: {},
  playbook: {},
  points: [],
  rules: {},
  styles: {},
  title: '',
}

Playbook.propTypes = {
  buttonlabel: PropTypes.string,
  color: PropTypes.string,
  description: PropTypes.array,
  image: PropTypes.object,
  playbook: PropTypes.object,
  points: PropTypes.array,
  title: PropTypes.string,
  rules: PropTypes.object,
  setEmergentModal: PropTypes.func,
  styles: PropTypes.object,
}

export default connectFela(styles)(Playbook)
