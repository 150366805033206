import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import BlockContent from '@sanity/block-content-to-react'
import { connect as connectFela } from "react-fela"

import Container from "components/container"
import Layout from "components/layout"
import P from "components/typography/P"
import Ul from "components/typography/UL"
import ParagraphTitle from "components/ParagraphTitle"
import Playbook from "./playbook"
import ResourcePageHeader from "./resourcePageHeader"
import colors, { getHexColor } from "theme/colors"
import styles from "./styles"
import { commonMarks } from "utilities/serializers"
import { serializers as linkSerializers } from "components/BaseLink.js"
import BrochureDownload from "components/BrochureDownload"

/* Serializers */
const serializers = ({styles, rules}) => {
    return {
        list: props => ( <Ul {...props} type={props.type} extend={{styles: rules.list}}>{props.children}</Ul> ),
        listItem: props => {
            return <P tag={'li'} version={'medium'}>{props.children}</P>
        },
        types: {
            topLineBlock: (props) => {
                return <div className={styles.topLine}></div>
            },
            block: (props) => {
                const style = props.node.style

                if (style === 'xlarge') {
                    return <P extend={{styles: rules.xlarge}} version="xlarge">{props.children}</P>
                }

                if (style === 'title') {
                    return <ParagraphTitle extend={{container: rules.paragraphTitle}}>{props.children}</ParagraphTitle>
                }

                if (style === 'large') {
                    return <P version='large' extend={{styles: rules.large}}>{props.children}</P>
                }

                if (style === 'normal') {
                    return <P version='body' extend={{styles: rules.normal}}>{props.children}</P>
                }
                return BlockContent.defaultSerializers.types.block(props)
            },
        },
        marks: {
            ...linkSerializers(styles, rules),
            ...commonMarks({styles, rules}),
        }
    }
}

const LandingCampaignPage = ({ path, pageContext, data, rules, styles }) => {
    const {
        title,
        meta,
        slug,
        intro,
        headerImage,
        color,
        circleColor,
        _rawContent,
        _rawBookBlock, bookBlock,
        _rawBrochureDownload, brochureDownload,
        subscriptionForm
    } = data.sanityLandingCampaignPage

    const pageColor = colors[`color${color || 'Human'}100`]
    const linkColor = colors[`color${color || 'Human'}500`]

    return (
      <Layout
        pageColor={pageColor}
        linkColor={linkColor}
        title={title}
        slug={{ current: path }}
        meta={meta || {}}
        settings={data.settings}
        jobs={data.jobs}
        country={pageContext.country}
      >
          <ResourcePageHeader
            title={title}
            intro={intro}
            pageColor={circleColor ? getHexColor(circleColor, '') : pageColor}
            extend={{introContainer: rules.intro}}
            headerImage={headerImage} />

            {brochureDownload?.brochure && <Container tag={'section'} withContentArea5 extend={{styles: rules.brochureDownload}}>
                <BrochureDownload
                    extraLeft
                    color={color}
                    title={brochureDownload.title}
                    brochure={brochureDownload.brochure}
                    description={_rawBrochureDownload.description}
                    image={brochureDownload.image}
                    points={_rawBrochureDownload.points}
                    buttonlabel={brochureDownload.buttonlabel} />
            </Container>}

            {bookBlock && <Container tag={'section'} withContentArea5 extend={{styles: rules.playbook}}>
                <Playbook
                    extraLeft
                    color={color}
                    title={bookBlock.title}
                    playbook={bookBlock.document}
                    description={_rawBookBlock.description}
                    image={bookBlock.image}
                    points={_rawBookBlock.points}
                    buttonlabel={bookBlock.buttonlabel} />
            </Container>}

            {_rawContent && <Container withContentArea5 tag={'section'} extend={{styles: rules.container}}>
                <Container extend={{styles: rules.contentContainer}}>
                    <BlockContent className={styles.content} blocks={_rawContent} serializers={serializers({rules, styles})}
                                  imageOptions={{w: 1263, h: 1016, fit: 'max'}}
                                  projectId={process.env.SANITY_PROJECT_ID || process.env.GATSBY_SANITY_PROJECT_ID}
                                  dataset={process.env.SANITY_DATASET || process.env.GATSBY_SANITY_DATASET}
                    />
                </Container>
            </Container>}
        </Layout>
    )
}

LandingCampaignPage.defaultProps = {
    rules: {},
    styles: {},
}

LandingCampaignPage.propTypes = {
    data: PropTypes.object.isRequired,
    rules: PropTypes.object,
    styles: PropTypes.object,
}

export default connectFela(styles)(LandingCampaignPage)

export const pageQuery = graphql`
query($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }
        sanityLandingCampaignPage(countrySet: { eq: $country }, slug: { current: { eq: $slug }}) {
           countrySet
            title
            meta {
                title
                description
                other {
                    property
                    content
                }
            }
            intro
            headerImage {
                asset {
                  gatsbyImageData(layout: CONSTRAINED, width:  1440)
                }
            }
            slug {
                current
            }
            circleColor
            color
            contentAlign

            _rawBrochureDownload
            brochureDownload {
                title
                buttonlabel
                points

                brochure {
                    asset {
                        url
                        localFile {
                          publicURL
                          name
                          internal {
                            contentDigest
                          }
                        }
                        originalFilename
                    }
                }
                image {
                    credits
                    asset {
                      gatsbyImageData(layout: CONSTRAINED, width:  1440)
                    }
                }
            }

            _rawBookBlock
            bookBlock {
                title
                buttonlabel
                points
                document {
                    title
                    file {
                        asset {
                            localFile {
                                publicURL
                                name
                                internal {
                                    contentDigest
                                }
                            }
                            originalFilename
                        }
                    }
                }
                image {
                    credits
                    asset {
                      gatsbyImageData(layout: CONSTRAINED, width:  1440)
                    }
                }
            }
            _rawContent(resolveReferences: {maxDepth: 10})

            subscriptionForm {
                title
                _rawContent
                image {
                    asset {
                      gatsbyImageData(layout: CONSTRAINED, width:  1440)
                    }
                }
                form {
                    title
                    image {
                        asset {
                          gatsbyImageData(layout: CONSTRAINED, width:  624)
                        }
                    }
                    thankyoumessage
                    cmListID
                }
            }

        }
    }
`
