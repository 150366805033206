import fluidValues from "utilities/fluidValues"

const styles = ({ data: { sanityLandingCampaignPage: { color, contentAlign } } }) => ({
    intro: {
        paddingBottom: "0 !important",
    },
    brochureDownload: {
        zIndex: 2,
        ...fluidValues({
            marginBottom: [90, 120]
        }),
    },
    topLine: {
        ...fluidValues({
            height: [120, 240],
            marginBottom: [38, 55],
            marginTop: [-34, -50],
        }),
        borderLeft: '1px solid black',
    },
    container: {
        zIndex: 1000000,
        extend: {
            condition: contentAlign,
            style: {
                display: 'flex',
                width: 'auto',
            }
        }
    },
    contentContainer: {
        extend: {
            condition: contentAlign,
            style: {
                width: 'auto'
            }
        }
    },
    playbook:{
        zIndex: 2,
        ...fluidValues({
          marginTop: [30, -10],
          marginBottom: [90, 200]
        }),
    },
})
export default styles
